import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const Seo = ({ title, description, image }) => (
  <Head>
    {title && <title>{title}</title>}
    {description && <meta name="description" content={description} />}

    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image} />}
  </Head>
);

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default Seo;
